import React from "react";
import dynamic from "next/dynamic";

import Seo from "components/Seo";
import Layout from "components/Layout";
import Loading from "components/Loading";

const HomePage = dynamic(() => import("pages/Home"), {
  loading: Loading,
});

export default function Home() {
  return (
    <Layout>
      <Seo description="WYSZUKIWARKA NIERUCHOMOŚCI Sprawdź bezpłatnie ceny 10 tys. mieszkań" />
      <HomePage />
    </Layout>
  );
}
